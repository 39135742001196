import React, { Component } from "react"
import Img from "gatsby-image"

import "./expandedPropertyGridItem.sass"

class ExpandedPropertyGridItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="property__grid__item">
        <div className="property__grid__item__photo">
          <Img fluid={this.props.fluid}></Img>
        </div>
        <div className="property__grid__item__name">
          <h4>{this.props.title}</h4>
        </div>
        <div className="property__grid__item__sq">
          <p>{this.props.address.street_address}</p>
          <p>{this.props.address.city_state_zip}</p>
        </div>
        <div className="property__grid__item__sq">
          <p>{this.props.squareft}</p>
        </div>
      </div>
    )
  }
}

export default ExpandedPropertyGridItem
