import React, { Component } from "react"
class SetP extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <p
        style={{ ...this.props.style }}
        className={
          this.props.className !== undefined ? this.props.className : ""
        }
        dangerouslySetInnerHTML={{ __html: this.props.children }}
      ></p>
    )
  }
}

export default SetP
