import React, { Component } from "react"
import BackgroundImage from "gatsby-background-image"
import SetP from "../../utility/SetP"
import AboutIcon from "../../aboutIcon/aboutIcon"

//styles
import "./about.sass"

class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentWidth: "asdfasdfas",
    }
  }

  // componentWillMount() {
  //   console.log(this.props, "about")
  // }

  render() {
    return (
      <div id="aboutid">
        <div
          className="section__container "
          style={
            {
              // backgroundColor: "white",
              // backgroundColor: "rgb(239, 239, 239)",
              // paddingTop: this.props.navHeight,
            }
          }
        >
          <div className="icon-wrapper">
            <div className="section__container__content fit  space">
              <div className="about">
                {/* <h2 className="section__title">{this.props.data.title}</h2> */}
                <div className="about__grid">
                  <div className="about__grid__icons">
                    {this.props.data.acf.icon_bullet_points.map((i, index) => (
                      <AboutIcon
                        icon={i.icon.localFile.childImageSharp.fluid}
                        text={i.bullet_point}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section__container__content full ">
            <div className="about__desc">
              {/* <BackgroundImage
                Tag="div"
                className="about__bottom__bg"
                backgroundColor={`#c9e0fc`}
                fadeIn={true}
                loading="eager"
                fluid={
                  this.props.data.acf.image.localFile.childImageSharp.fluid
                }
              > */}
              {/* <div className="bg__hero">
          <div className="bg__hero__image">
            <Img
              fluid={this.props.heroImage.localFile.childImageSharp.fluid}
              alt={this.props.heroImage.alt_text}
            />
          </div>
        </div> */}
              {/* <div className="bg__scroll">
          <A to="#about">
            <Img
              fluid={this.props.data.acf.arrow.localFile.childImageSharp.fluid}
            />
          </A>
        </div> */}

              <div className="about__desc__bg__overlay">
                <div className="section__container__content fit">
                  <div className="about__desc__bg__overlay__text">
                    <h2>{this.props.data.title}</h2>
                    <SetP>{this.props.data.acf.body}</SetP>
                  </div>
                </div>
              </div>
              {/* </BackgroundImage> */}
            </div>
          </div>
          {/* removed space-top class */}
        </div>
      </div>
    )
  }
}

export default About
