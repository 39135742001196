import React, { Component } from "react"
import Img from "gatsby-image"
import "./contact.sass"
import SetP from "../../utility/SetP"
class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      path: "",
    }
  }
  componentDidMount() {
    this.setState({ path: window.location.href })
  }

  render() {
    return (
      <div className="section__container contact__section__container">
        <div className="section__container__content fit center space">
          <h2 className="section__title contact__page-title">
            {this.props.data.title}
          </h2>
          <div className="contact">
            <div className="contact__info">
              <div className="contact__info__image">
                <div className="contact__info__image__container">
                  <Img
                    fluid={this.props.logo.localFile.childImageSharp.fluid}
                    alt={this.props.logo.alt_text}
                  />
                </div>
              </div>

              <SetP>{this.props.data.acf.address}</SetP>
              {/* <p>Phone: {data.acf.contact_block.phone_number}</p> */}
              <p>
                Email:{" "}
                <span className="contact__info__email">
                  <a href={`mailto:${this.props.data.acf.email}`}>
                    {this.props.data.acf.email}
                  </a>
                </span>
              </p>
            </div>
            {/* <hr /> */}
            <div className="divider">
              <div className="divider__line" />
            </div>
            <div className="contact__form">
              <form
                action="https://getsimpleform.com/messages?form_api_token=e07afecd7e586621b98a5e464c17d023"
                method="post"
              >
                {/* the redirect_to is optional, the form will redirect to the referrer on submission*/}
                <input
                  type="hidden"
                  name="redirect_to"
                  value={this.state.path}
                />
                {/*all your input fields here...*/}
                <label>
                  <p className="visuallyhidden">Email name</p>

                  <input type="text" placeholder="Name" />
                </label>
                <label>
                  <p className="visuallyhidden">Email</p>

                  <input name="email" type="email" placeholder="E-Mail" />
                </label>
                <label>
                  <p className="visuallyhidden">phone number</p>

                  <input type="tel" placeholder="Phone Number" />
                </label>
                <label>
                  <p>Message</p>
                  <textarea
                    name="message"
                    id=""
                    cols="30"
                    rows="6"
                    placeholder=""
                  />
                </label>
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
