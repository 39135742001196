import React, { Component } from "react"
import PortfolioItem from "../../portfolioItem/portfolioItem"
import PortfolioIndex from "../../portfolioViews/portfolioIndex"
import PortfolioExpanded from "../../portfolioViews/portfolioExpanded"
import { CSSTransition } from "react-transition-group"
import ExpandedPropertyGridItem from "../../expandedPropertyGridItem/expandedPropertyGridItem"
import "./portfolio.sass"

import buttonArrow from "../../../images/psp_arrow.svg"

class Portfolio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      indexShowing: true,
      // expandedProperty: this.props.data[1],
      enterIndex: false,
    }
  }
  componentWillMount() {
    console.log("from index", this.props)
  }
  indexButton() {
    return (
      <div
        className="portfolio__button index"
        onClick={this.changePortfolioView}
      >
        <span>
          <p>Click On A Property For More Information</p>
          <img src={buttonArrow} alt="arrow" />
        </span>
      </div>
    )
  }
  expandedButton() {
    return (
      <button
        className="portfolio__button expanded"
        onClick={this.changePortfolioView}
      >
        <span>
          <img src={buttonArrow} alt="arrow" />
          <p>View All Properties</p>
        </span>
      </button>
    )
  }
  changePortfolioView = () => {
    this.setState(
      {
        indexShowing: !this.state.indexShowing,
      },
      () => {
        this.scrollIntoView()
      }
    )
  }
  getClickedProperty = index => {
    this.setState({
      expandedProperty: this.props.data[index],
    })
    this.changePortfolioView()
    return this.index
  }
  scrollIntoView = () => {
    setTimeout(() => {
      document.querySelector("#expanded_top").scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    })
  }
  render() {
    return (
      <div id="portfolio" id="expanded_top">
        <div
          className="section__container"
          style={{ backgroundColor: "white" }} // paddingTop: this.props.navHeight }}//cole added
        >
          <div className="section__container__content fit space">
            <h2 className="section__title">Portfolio</h2>
            {/* {this.state.indexShowing
              ? this.indexButton()
              : this.expandedButton()} */}

            <div className="portfolio__expanded__bottom">
              <div className="portfolio__expanded__bottom__title">
                {/* <h5>Additional Properties</h5> */}
              </div>
              <div className="portfolio__expanded__bottom__grid">
                {this.props.data.properties.map((p, index) => (
                  <ExpandedPropertyGridItem
                    fluid={p.photo.localFile.childImageSharp.fluid}
                    alt={p.photo.alt_text}
                    title={p.name}
                    squareft={
                      Number(p.square_footage).toLocaleString() + " sq ft"
                    }
                    address={p.address}
                    key={index}
                  />
                ))}
              </div>
            </div>
            {/* <button onClick={this.changePortfolioView}>FADE IN INDEX</button>
          <button onClick={this.changePortfolioView}>FADE IN Expanded</button> */}
            <div className="portfolio">
              {/* <CSSTransition
                in={this.state.indexShowing}
                timeout={300}
                classNames="index"
                unmountOnExit
              >
                <PortfolioIndex
                  properties={this.props.data}
                  //gets portfolio item index to pass proper property to expanded view
                  getClickedProperty={this.getClickedProperty}
                />
              </CSSTransition>
              <CSSTransition
                in={!this.state.indexShowing}
                timeout={300}
                classNames="test"
                unmountOnExit
              >
                <PortfolioExpanded property={this.state.expandedProperty} />
              </CSSTransition> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Portfolio
